var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"box-card param-box-card",attrs:{"shadow":"hover"}},[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',{staticClass:"flex-column justify-start",attrs:{"span":5}},[_c('div',{staticClass:"param-title"},[_vm._v(_vm._s(_vm.param.key))]),_c('div',[(_vm.param.is_editing)?_c('el-button',{attrs:{"icon":"el-icon-check","circle":""},on:{"click":function($event){return _vm.submit()}}}):_c('el-button',{attrs:{"icon":"el-icon-edit","circle":""},on:{"click":function($event){return _vm.edit()}}}),_c('el-button',{attrs:{"icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.remove()}}})],1)]),_c('el-col',{attrs:{"span":19}},[(_vm.param.is_editing)?_c('el-form',{ref:'paramForm' + _vm.index,staticClass:"additional-param-form",attrs:{"rules":_vm.rules,"model":_vm.param}},[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"key","label":_vm.__('Key')}},[_c('el-input',{model:{value:(_vm.param.key),callback:function ($$v) {_vm.$set(_vm.param, "key", $$v)},expression:"param.key"}})],1)],1),_c('el-col',[_c('el-form-item',{attrs:{"prop":"label","label":_vm.__('Label')}},[_c('el-input',{model:{value:(_vm.param.label),callback:function ($$v) {_vm.$set(_vm.param, "label", $$v)},expression:"param.label"}})],1)],1)],1),_c('el-form-item',{attrs:{"prop":"path","label":_vm.__('Path')}},[_c('el-input',{model:{value:(_vm.param.path),callback:function ($$v) {_vm.$set(_vm.param, "path", $$v)},expression:"param.path"}})],1),(
            _vm.parameterType === 'request_param' ||
              _vm.parameterType === 'auth_param'
          )?[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"type","label":_vm.__('Get parameter from')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","default-first-option":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}},_vm._l((_vm.requestParameterTypeOptions),function(requestParameterOption){return _c('el-option',{key:'_request_param_source_' + requestParameterOption.value,attrs:{"label":requestParameterOption.label,"value":requestParameterOption.value}})}),1)],1)],1),(
                _vm.requestParameterFormTypes && _vm.requestParameterFormTypes.length
              )?[(_vm.paramHasInput)?_c('el-col',[_c('el-form-item',{attrs:{"prop":"form_type","label":this.requestParameterFormTypes[0].label}},[_c('el-input',{model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}})],1)],1):_c('el-col',[_c('el-form-item',{attrs:{"prop":"form_type","label":_vm.__('Input type')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","default-first-option":""},model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}},_vm._l((_vm.requestParameterFormTypes),function(requestParameterFormType){return _c('el-option',{key:'_request_param_form_type_' +
                          requestParameterFormType.value,attrs:{"label":requestParameterFormType.label,"value":requestParameterFormType.value}})}),1)],1)],1)]:_vm._e()],2),_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"default_data","label":_vm.__('Default data')}},[_c('el-input',{model:{value:(_vm.param.default_data),callback:function ($$v) {_vm.$set(_vm.param, "default_data", $$v)},expression:"param.default_data"}})],1)],1),_c('el-col',[_c('el-form-item',{attrs:{"prop":"test_data","label":_vm.__('Test data')}},[_c('el-input',{model:{value:(_vm.param.test_data),callback:function ($$v) {_vm.$set(_vm.param, "test_data", $$v)},expression:"param.test_data"}})],1)],1)],1)]:(_vm.parameterType === 'response_param')?[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"type","label":_vm.__('Get response from')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","default-first-option":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}},_vm._l((_vm.responseParameterTypeOptions),function(responseParameterOption){return _c('el-option',{key:'_response_param_source_' +
                        responseParameterOption.value,attrs:{"label":responseParameterOption.label,"value":responseParameterOption.value}})}),1)],1)],1),(
                _vm.responseParameterFormTypes &&
                  _vm.responseParameterFormTypes.length
              )?[(_vm.paramHasInput)?_c('el-col',[_c('el-form-item',{attrs:{"prop":"form_type","label":this.responseParameterFormTypes[0].label}},[_c('el-input',{model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}})],1)],1):_c('el-col',[_c('el-form-item',{attrs:{"prop":"form_type","label":_vm.__('Input type')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"default-first-option":"","filterable":""},model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}},_vm._l((_vm.responseParameterFormTypes),function(responseParameterFormType){return _c('el-option',{key:'_response_param_form_type_' +
                          responseParameterFormType.value,attrs:{"label":responseParameterFormType.label,"value":responseParameterFormType.value}})}),1)],1)],1)]:_vm._e()],2),_c('el-form-item',{attrs:{"prop":"default_data","label":_vm.__('Default data')}},[_c('el-input',{model:{value:(_vm.param.default_data),callback:function ($$v) {_vm.$set(_vm.param, "default_data", $$v)},expression:"param.default_data"}})],1)]:_vm._e()],2):_c('view-param',{attrs:{"param":_vm.param}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }