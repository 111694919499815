<template>
  <div class="custom-payload-param-view">
    <el-row :gutter="10" class="pb-2">
      <el-col :span="12">
        <div class="param-display-label">{{ __("Key") }}</div>
        <div class="param-display-value">{{ param.key }}</div>
      </el-col>
      <el-col :span="12">
        <div class="param-display-label">{{ __("Label") }}</div>
        <div class="param-display-value">{{ param.label }}</div>
      </el-col>
    </el-row>
    <div class="pb-2">
      <div class="param-display-label">{{ __("Path") }}</div>
      <div class="param-display-value">
        {{ param.path }}
      </div>
    </div>
    <div class="pb-2">
      <div class="param-display-label">{{ __("Parameter Type") }}</div>
      <div class="param-display-value">
        <el-tag size="small">{{ param.type }}</el-tag>
      </div>
    </div>
    <el-row :gutter="10" class="pb-2">
      <el-col :span="12" v-if="param.default_data && param.default_data.length">
        <div class="param-display-label">{{ __("Default data") }}</div>
        <div class="param-display-value">
          {{ param.default_data }}
        </div>
      </el-col>
      <el-col :span="12" v-if="param.test_data && param.test_data.length">
        <div class="param-display-label">{{ __("Test data") }}</div>
        <div class="param-display-value">{{ param.test_data }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    param: {
      type: Object,
      require: true
    }
  }
};
</script>

<style scoped lang="scss">
.param-display-label {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  color: #a0a8b5;
  line-height: 1.2rem;
}

.param-display-value {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--site-default-font-color);
}
</style>
