<template>
  <div style="padding-top: 20px;">
    <el-row type="flex" :gutter="20">
      <el-col :span="5">
        <div class="param-title">{{ paymentProcessTypeData.label }}</div>
        <div>
          <el-button
            v-if="paymentProcessTypeData.is_editing"
            icon="el-icon-check"
            circle
            @click="submit()"
          ></el-button>
          <el-button
            v-else
            icon="el-icon-edit"
            circle
            @click="edit()"
          ></el-button>
          <el-button icon="el-icon-delete" circle @click="remove()"></el-button>
        </div>
      </el-col>
      <el-col :span="19">
        <el-form
          :rules="rules"
          :ref="'paymentProcessTypeForm' + index"
          :model="paymentProcessTypeData"
          v-if="paymentProcessTypeData.is_editing"
          class="payment-process-type-form"
        >
          <el-row type="flex" :gutter="20">
            <el-col>
              <el-form-item prop="key" :label="__('Key')">
                <el-input v-model="paymentProcessTypeData.key"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="label" :label="__('Label')">
                <el-input v-model="paymentProcessTypeData.label"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="http_method" :label="__('HTTP method')">
            <el-select
              v-model="paymentProcessTypeData.http_method"
              default-first-option
              style="width: 100%;"
              filterable
            >
              <el-option value="get" label="GET"></el-option>
              <el-option value="post" label="POST"></el-option>
              <el-option value="put" label="PUT"></el-option>
              <el-option
                value="backend_function"
                :label="__('Backend function')"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-row
            type="flex"
            :gutter="20"
            v-if="paymentProcessTypeData.http_method === 'backend_function'"
          >
            <el-col>
              <el-form-item prop="class_name" :label="__('Class name')">
                <el-input v-model="className"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="function_name" :label="__('Function name')">
                <el-input v-model="functionName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="resource_path" :label="__('Resource path')">
            <el-input v-model="paymentProcessTypeData.resource_path"></el-input>
          </el-form-item>

          <el-form-item
            prop="request_params.required"
            :label="__('Request parameters (Required)')"
          >
            <el-select
              v-model="requiredParameters"
              multiple
              filterable
              :placeholder="__('Select required parameters')"
              default-first-option
              style="width: 100%"
            >
              <el-option
                v-for="(param, key) in requestParams"
                :key="'_request_params_required_' + key"
                :label="param.label"
                :value="param.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="request_params.optional"
            :label="__('Request parameters (Optional)')"
          >
            <el-select
              v-model="optionalParameters"
              multiple
              filterable
              :placeholder="__('Select optional parameters')"
              default-first-option
              style="width: 100%"
            >
              <el-option
                v-for="(param, key) in requestParams"
                :key="'_request_params_optional_' + key"
                :label="param.label"
                :value="param.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="url_params" :label="__('URL parameters')">
            <el-select
              v-model="urlParameters"
              multiple
              filterable
              :placeholder="__('Select URL parameters')"
              default-first-option
              style="width: 100%"
            >
              <el-option
                v-for="(param, key) in requestParams"
                :key="'_url_params_' + key"
                :label="param.label"
                :value="param.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="response_params"
            :label="__('Response parameters')"
          >
            <el-select
              v-model="responseParameters"
              multiple
              filterable
              default-first-option
              :placeholder="__('Select response parameters')"
              style="width: 100%"
            >
              <el-option
                v-for="(param, key) in responseParams"
                :key="'_response_params_optional_' + key"
                :label="param.label"
                :value="param.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div v-else class="py-2">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="param-display-label">{{ __("Key") }}</div>
              <div class="param-display-value">
                {{ paymentProcessTypeData.key }}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="param-display-label">{{ __("Label") }}</div>
              <div class="param-display-value">
                {{ paymentProcessTypeData.label }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="param-display-label">{{ __("HTTP method") }}</div>
              <div class="param-display-value">
                {{ paymentProcessTypeData.http_method }}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="param-display-label">{{ __("Resource path") }}</div>
              <div class="param-display-value">
                {{ paymentProcessTypeData.resource_path }}
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <hr v-if="!isLast" style="margin-top: 20px;" />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    index: {
      required: true
    },

    isLast: {
      default: true,
      type: Boolean
    },

    paymentProcessType: {
      required: true
    },

    requestParams: {
      type: [Object, Array],
      default: () => null
    },

    responseParams: {
      type: [Object, Array],
      default: () => null
    }
  },

  data() {
    return {
      rules: {
        key: [
          { required: true, trigger: "blur", message: __("Key is required") }
        ]
      },
      className: "",
      functionName: "",
      paymentProcessTypeData: _.cloneDeep(this.paymentProcessType),
      requiredParameters: [],
      optionalParameters: [],
      urlParameters: [],
      responseParameters: []
    };
  },

  methods: {
    remove() {
      this.$emit("remove");
    },

    submit() {
      this.$refs["paymentProcessTypeForm" + this.index].validate(valid => {
        if (valid) {
          this.$set(
            this.paymentProcessTypeData.request_params,
            "required",
            this.requiredParameters
          );
          this.$set(
            this.paymentProcessTypeData.request_params,
            "optional",
            this.optionalParameters
          );
          this.$set(
            this.paymentProcessTypeData,
            "url_params",
            this.urlParameters
          );
          this.$set(
            this.paymentProcessTypeData,
            "response_params",
            this.responseParameters
          );

          if (this.paymentProcessTypeData.http_method === "backend_function") {
            this.paymentProcessTypeData.http_method =
              "backend_function::" + this.className + "," + this.functionName;
          }

          if (this.paymentProcessTypeData.label == "") {
            this.paymentProcessTypeData.label = this.paymentProcessTypeData.key.replace(
              / /g,
              "_"
            );
          }
          this.$set(this.paymentProcessTypeData, "is_editing", false);
          delete this.paymentProcessTypeData.is_editing;
          this.$emit("update", this.index, this.paymentProcessTypeData);
        }
      });
    },

    edit() {
      this.$set(this.paymentProcessTypeData, "is_editing", true);
    }
  },

  created() {
    this.requiredParameters = Object.values(
      this.paymentProcessTypeData.request_params.required
    );
    this.optionalParameters = Object.values(
      this.paymentProcessTypeData.request_params.optional
    );
    this.urlParameters = Object.values(this.paymentProcessTypeData.url_params);
    this.responseParameters = Object.values(
      this.paymentProcessTypeData.response_params
    );
    if (this.paymentProcessTypeData.http_method) {
      if (
        this.paymentProcessTypeData.http_method.startsWith("backend_function::")
      ) {
        const httpMethodArray = this.paymentProcessTypeData.http_method.split(
          "::"
        );
        if (httpMethodArray && httpMethodArray.length > 1) {
          this.paymentProcessTypeData.http_method = httpMethodArray[0];
          const funcString = httpMethodArray[1];
          const funcArray = funcString.split(",");
          if (funcArray && funcArray.length > 1) {
            this.className = funcArray[0];
            this.functionName = funcArray[1];
          }
        }
      } else {
        this.paymentProcessTypeData.http_method = this.paymentProcessTypeData.http_method.toLowerCase();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";

::v-deep .additional-param-form {
  .el-form-item__label {
    font-weight: 100;
    font-size: 16px;
  }
}

.param-title {
  padding-bottom: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2rem;
  color: #a0a8b5;
}

.param-display-label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #a0a8b5;
  line-height: 1.2rem;
}
</style>
