import { render, staticRenderFns } from "./viewParam.vue?vue&type=template&id=324ac88c&scoped=true"
import script from "./viewParam.vue?vue&type=script&lang=js"
export * from "./viewParam.vue?vue&type=script&lang=js"
import style0 from "./viewParam.vue?vue&type=style&index=0&id=324ac88c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324ac88c",
  null
  
)

export default component.exports