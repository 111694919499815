<template>
  <el-card class="box-card param-box-card" shadow="hover">
    <el-row type="flex" :gutter="20">
      <el-col :span="5" class="flex-column justify-start">
        <div class="param-title">{{ param.key }}</div>
        <div>
          <el-button
            v-if="param.is_editing"
            icon="el-icon-check"
            circle
            @click="submit()"
          ></el-button>
          <el-button
            v-else
            icon="el-icon-edit"
            circle
            @click="edit()"
          ></el-button>
          <el-button icon="el-icon-delete" circle @click="remove()"></el-button>
        </div>
      </el-col>
      <el-col :span="19">
        <el-form
          :rules="rules"
          :ref="'paramForm' + index"
          :model="param"
          v-if="param.is_editing"
          class="additional-param-form"
        >
          <el-row type="flex" :gutter="20">
            <el-col>
              <el-form-item prop="key" :label="__('Key')">
                <el-input v-model="param.key"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="label" :label="__('Label')">
                <el-input v-model="param.label"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="path" :label="__('Path')">
            <el-input v-model="param.path"></el-input>
          </el-form-item>

          <template
            v-if="
              parameterType === 'request_param' ||
                parameterType === 'auth_param'
            "
          >
            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item prop="type" :label="__('Get parameter from')">
                  <el-select
                    v-model="source"
                    style="width: 100%;"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="requestParameterOption in requestParameterTypeOptions"
                      :label="requestParameterOption.label"
                      :value="requestParameterOption.value"
                      :key="
                        '_request_param_source_' + requestParameterOption.value
                      "
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <template
                v-if="
                  requestParameterFormTypes && requestParameterFormTypes.length
                "
              >
                <el-col v-if="paramHasInput">
                  <el-form-item
                    prop="form_type"
                    :label="this.requestParameterFormTypes[0].label"
                  >
                    <el-input v-model="formType"></el-input>
                  </el-form-item>
                </el-col>

                <el-col v-else>
                  <el-form-item prop="form_type" :label="__('Input type')">
                    <el-select
                      v-model="formType"
                      style="width: 100%;"
                      filterable
                      default-first-option
                    >
                      <el-option
                        v-for="requestParameterFormType in requestParameterFormTypes"
                        :label="requestParameterFormType.label"
                        :value="requestParameterFormType.value"
                        :key="
                          '_request_param_form_type_' +
                            requestParameterFormType.value
                        "
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>

            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item prop="default_data" :label="__('Default data')">
                  <el-input v-model="param.default_data"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item prop="test_data" :label="__('Test data')">
                  <el-input v-model="param.test_data"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <template v-else-if="parameterType === 'response_param'">
            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item prop="type" :label="__('Get response from')">
                  <el-select
                    v-model="source"
                    style="width: 100%;"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="responseParameterOption in responseParameterTypeOptions"
                      :label="responseParameterOption.label"
                      :value="responseParameterOption.value"
                      :key="
                        '_response_param_source_' +
                          responseParameterOption.value
                      "
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <template
                v-if="
                  responseParameterFormTypes &&
                    responseParameterFormTypes.length
                "
              >
                <el-col v-if="paramHasInput">
                  <el-form-item
                    prop="form_type"
                    :label="this.responseParameterFormTypes[0].label"
                  >
                    <el-input v-model="formType"></el-input>
                  </el-form-item>
                </el-col>

                <el-col v-else>
                  <el-form-item prop="form_type" :label="__('Input type')">
                    <el-select
                      v-model="formType"
                      style="width: 100%;"
                      default-first-option
                      filterable
                    >
                      <el-option
                        v-for="responseParameterFormType in responseParameterFormTypes"
                        :label="responseParameterFormType.label"
                        :value="responseParameterFormType.value"
                        :key="
                          '_response_param_form_type_' +
                            responseParameterFormType.value
                        "
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>

            <el-form-item prop="default_data" :label="__('Default data')">
              <el-input v-model="param.default_data"></el-input>
            </el-form-item>
          </template>
        </el-form>
        <view-param v-else :param="param"></view-param>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import _ from "lodash";
import ViewParam from "./viewParam";
export default {
  name: "ParamComponent",
  components: { ViewParam },
  props: {
    parameterType: {
      type: String,
      default: "request_param"
    },
    parameter: {
      required: true
    },
    index: {
      required: true
    },

    paymentProcessTypes: {
      type: [Object, Array],
      default: () => null
    },

    isLast: {
      default: true,
      type: Boolean
    }
  },

  computed: {
    requestParameterFormTypes() {
      return _.get(
        _.find(this.requestParameterTypeOptions, { value: this.source }),
        "children",
        []
      );
    },

    responseParameterFormTypes() {
      return _.get(
        _.find(this.responseParameterTypeOptions, { value: this.source }),
        "children",
        []
      );
    },

    paramHasInput() {
      const paramWithInputs = [
        "payment_card_expiry",
        "conditions_field_value",
        "if_else"
      ];
      return paramWithInputs.includes(this.source);
    }
  },

  data() {
    return {
      rules: {
        key: [
          { required: true, trigger: "blur", message: __("Key is required") }
        ],
        type: [
          {
            required: true,
            trigger: "blur",
            message: __("Parameter type is required")
          }
        ]
      },
      source: "",
      formType: "",
      param: _.cloneDeep(this.parameter),
      requestParameterTypeOptions: [
        {
          value: "payment_gateway",
          label: __("Payment gateway"),
          children: [
            {
              value: "input",
              label: __("Input")
            }
          ]
        },
        {
          value: "payment_profile",
          label: __("Payment profile"),
          children: [
            {
              value: "input",
              label: __("Input")
            },
            {
              value: "file_upload",
              label: __("File upload")
            }
          ]
        },
        {
          value: "payment_node",
          label: __("Payment node"),
          children: [
            {
              value: "variable",
              label: __("Variable")
            }
          ]
        },
        {
          value: "payment_card_type",
          label: __("Payment Card Type")
        },
        {
          value: "payment_card_cvc",
          label: __("Payment Card CVC")
        },
        {
          value: "payment_card_number",
          label: __("Payment Card Number")
        },
        {
          value: "payment_card_expiry",
          label: __("Payment Card Expiry"),
          children: [
            {
              value: "mmyy",
              label: __("Expiry format")
            }
          ]
        },
        {
          value: "conditions_field_value",
          label: __("Conditions field value statement"),
          children: [
            {
              value: "",
              label: __("Statement")
            }
          ]
        },
        {
          value: "if_else",
          label: __("If else statement"),
          children: [
            {
              value: "",
              label: __("Statement")
            }
          ]
        }
      ],
      responseParameterTypeOptions: [
        {
          value: "curl_response_code",
          label: __("Curl response code")
        },
        {
          value: "response",
          label: __("Response")
        },
        {
          value: "conditions_field_value",
          label: __("Conditions field value statement"),
          children: [
            {
              value: "",
              label: __("Statement")
            }
          ]
        },
        {
          value: "if_else",
          label: __("If else statement"),
          children: [
            {
              value: "",
              label: __("Statement")
            }
          ]
        }
      ]
    };
  },

  methods: {
    remove() {
      this.$emit("remove", this.index);
    },

    submit() {
      this.$refs["paramForm" + this.index].validate(valid => {
        if (valid) {
          if (["conditions_field_value", "if_else"].includes(this.source)) {
            this.$set(this.param, "type", this.source);
            this.$set(this.param, this.source, this.formType);
          } else {
            this.param.type = this.source;
            if (this.formType !== "") {
              this.param.type += ":" + this.formType;
            }
          }

          if (this.param.path == "") {
            this.param.path = this.param.key;
          }

          if (this.param.label == "") {
            this.param.label = this.param.key.replace(/ /g, "_");
          }

          this.$set(this.param, "is_editing", false);
          delete this.param.is_editing;
          this.$emit("update", this.index, this.param);
        }
      });
    },

    edit() {
      this.$set(this.param, "is_editing", true);
    }
  },

  created() {
    if (this.param.key && this.param.key.length > 0) {
      this.$set(this.param, "is_editing", false);
    }

    if (this.parameter.type && this.parameter.type.length > 0) {
      const typeArray = this.parameter.type.split(":");
      if (typeArray) {
        this.source = typeArray[0];
        if (typeArray.length > 1) {
          this.formType = typeArray[1];
        } else {
          if (this.source === "if_else") {
            this.formType = this.param.if_else ? this.param.if_else : "";
          } else if (this.source === "conditions_field_value") {
            this.formType = this.param.conditions_field_value
              ? this.param.conditions_field_value
              : "";
          } else {
            this.formType = "";
          }
        }
      }
    }
  },

  watch: {
    source(newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) {
        let availableTypes = [...this.requestParameterFormTypes];
        if (this.parameterType === "response_param") {
          availableTypes = [...this.responseParameterFormTypes];
        }
        if (availableTypes[0] && availableTypes[0].value) {
          this.formType = availableTypes[0].value;
        } else {
          this.formType = "";
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";

.param-box-card {
  margin: 15px 0;
  border-radius: 0.75rem;

  .param-title {
    padding-bottom: 20px;
    text-transform: uppercase;
    font-size: 0.85rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #a0a8b5;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    justify-content: flex-start;
  }
}

::v-deep .additional-param-form {
  .el-form-item__label {
    font-weight: 100;
    font-size: 16px;
  }
}
</style>
