<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "PaymentGatewaysIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("paymentgateways", {
      contents: state => state.paymentGateways
    })
  },

  methods: {
    initContent() {
      return {
        payment_gateway_name: "",
        json_data: {
          api_url: {
            test: "",
            live: ""
          },
          authentication: {
            type: "none"
          },
          payment_process_types: {},
          request_parameters: {},
          response_parameters: {},
          request_type: "json",
          response_type: "json"
        }
      };
    }
  },

  mounted() {
    this.contentType = "PaymentGateway";
    this.primaryKey = "payment_gateway_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
