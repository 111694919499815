<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.payment_gateway_name }}</div>

      <el-button
        @click="openModal = true"
        class="editBtn"
        :disabled="!can('system.payment-gateways.write')"
        >{{ __("Edit") }}</el-button
      >
      <el-button
        @click="
          handleDelete(
            contentForm,
            'PaymentGateway',
            contentForm.payment_gateway_id
          )
        "
        class="deleteBtn"
        :disabled="!can('system.payment-gateways.write')"
        >{{ __("Delete") }}
      </el-button>

      <delete-item></delete-item>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <div class="payment-gateway-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Payment Gateway')" :contentId="id" />
          </el-col>
        </el-row>

        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            style="overflow-x: hidden;"
          >
            <el-col :span="12" :offset="6">
              <el-tabs
                v-model="activeTab"
                class="tabs"
                style="font-size: 1.2em;"
              >
                <el-tab-pane :label="__('Basic')" name="basic">
                  <el-form-item prop="payment_gateway_name" :label="__('Name')">
                    <el-input
                      v-model="contentForm.payment_gateway_name"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    prop="json_data.api_url.live"
                    :label="__('API URL live')"
                  >
                    <el-input
                      v-model="contentForm.json_data.api_url.live"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    prop="json_data.api_url.test"
                    :label="__('API URL test')"
                  >
                    <el-input
                      v-model="contentForm.json_data.api_url.test"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    prop="json_data.request_type"
                    :label="__('Request type')"
                  >
                    <el-select
                      v-model="contentForm.json_data.request_type"
                      style="width: 100%;"
                      default-first-option
                      filterable
                    >
                      <el-option
                        v-for="requestType in availableRequestTypes"
                        :key="'_request_type_' + requestType"
                        :value="requestType"
                        :label="requestType"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    prop="json_data.response_type"
                    :label="__('Response type')"
                  >
                    <el-select
                      v-model="contentForm.json_data.response_type"
                      style="width: 100%;"
                      default-first-option
                      filterable
                    >
                      <el-option
                        v-for="responseType in availableResponseTypes"
                        :key="'_response_type_' + responseType"
                        :value="responseType"
                        :label="responseType"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Request parameters')"
                  name="request_parameters"
                >
                  <div>
                    <el-button plain @click="addRequestParameter()">{{
                      __("Add request parameter")
                    }}</el-button>
                  </div>
                  <custom-payload-param
                    v-for="(param, index) in requestParams"
                    :ref="'reqRef' + index"
                    :payment-process-types="paymentProcessTypes"
                    parameter-type="request_param"
                    :parameter="param"
                    :index="index"
                    :key="'request_param' + index"
                    :is-last="index === requestParams.length - 1"
                    @remove="removeRequestParameter(index)"
                    @update="updateRequestParameter"
                  ></custom-payload-param>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Response parameters')"
                  name="response_parameters"
                >
                  <div>
                    <el-button plain @click="addResponseParameter">{{
                      __("Add response parameter")
                    }}</el-button>
                  </div>
                  <custom-payload-param
                    v-for="(param, index) in responseParams"
                    :ref="'respRef' + index"
                    :payment-process-types="paymentProcessTypes"
                    parameter-type="response_param"
                    :parameter="param"
                    :index="index"
                    :key="'response_param' + index"
                    :is-last="index === responseParams.length - 1"
                    @remove="removeResponseParameter(index)"
                    @update="updateResponseParameter"
                  ></custom-payload-param>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Authentication')"
                  name="authentication"
                >
                  <el-form-item :label="__('Authentication method')">
                    <el-select
                      v-model="contentForm.json_data.authentication.type"
                      style="width: 100%;"
                      @change="selectAuthenticationType"
                      default-first-option
                      filterable
                    >
                      <el-option value="none" :label="__('None')"></el-option>
                      <el-option
                        value="basic"
                        :label="__('Basic authentication')"
                      ></el-option>
                      <el-option
                        value="digest"
                        :label="__('Digest authentication')"
                      ></el-option>
                      <el-option
                        value="bearer_access_token"
                        :label="__('Bearer token authentication')"
                      ></el-option>
                      <el-option
                        value="api_key"
                        :label="__('Authenticate with api key')"
                      ></el-option>
                      <el-option
                        value="backend_function"
                        :label="__('Custom function')"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <template
                    v-if="
                      contentForm.json_data.authentication.type &&
                        contentForm.json_data.authentication.type !== 'none'
                    "
                  >
                    <template
                      v-if="
                        contentForm.json_data.authentication.type ===
                          'bearer_access_token'
                      "
                    >
                      <el-form-item
                        prop="json_data.authentication.bearer_access_token.http_method"
                        :label="__('Auth HTTP method')"
                      >
                        <el-input
                          v-model="
                            contentForm.json_data.authentication
                              .bearer_access_token.http_method
                          "
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        prop="json_data.authentication.bearer_access_token.resource_path"
                        :label="__('Auth Resource path')"
                      >
                        <el-input
                          v-model="
                            contentForm.json_data.authentication
                              .bearer_access_token.resource_path
                          "
                        ></el-input>
                      </el-form-item>
                      <el-form-item :label="__('Response parameters')">
                        <el-select
                          style="width: 100%"
                          v-model="authResponseParams"
                          multiple
                          filterable
                          default-first-option
                          :placeholder="__('Select auth parameters')"
                        >
                          <el-option
                            v-for="(param, key) in responseParams"
                            :key="'_auth_response_params_' + key"
                            :label="param.label"
                            :value="param.key"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>

                    <el-form-item
                      v-else-if="
                        contentForm.json_data.authentication.type ===
                          'backend_function'
                      "
                      prop="json_data.authentication.backend_function.function"
                      label="Class:Function"
                    >
                      <el-input
                        v-model="
                          contentForm.json_data.authentication.backend_function
                            .function
                        "
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="__('Authentication parameters')">
                      <el-select
                        style="width: 100%"
                        v-model="authRequestParams"
                        multiple
                        filterable
                        default-first-option
                        :placeholder="__('Select auth parameters')"
                      >
                        <el-option
                          v-for="(param, key) in requestParams"
                          :key="'_auth_request_params_' + key"
                          :label="param.label"
                          :value="param.key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Payment process types')"
                  name="payment_process_types"
                >
                  <div>
                    <el-button plain @click="addPaymentProcessType">{{
                      __("Add payment process type")
                    }}</el-button>
                  </div>

                  <payment-process-type
                    v-for="(paymentProcessType, index) in paymentProcessTypes"
                    :key="'_payment_process_type_' + index"
                    :ref="'pptRef' + index"
                    :index="index"
                    :payment-process-type="paymentProcessType"
                    :request-params="requestParams"
                    :response-params="responseParams"
                    :is-last="index === paymentProcessTypes.length - 1"
                    @remove="removePaymentProcessType(index)"
                    @update="updatePaymentProcessType"
                  >
                  </payment-process-type>
                </el-tab-pane>
              </el-tabs>
              <el-form-item style="margin-top: 15px;">
                <save-button
                  type="primary"
                  @click="submitForm"
                  class="submitBtn"
                  :disabled="!can('system.payment-gateways.write')"
                  :primaryKey="id"
                />
                <el-button @click="closeModal" class="cancelBtn">{{
                  __("Cancel")
                }}</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import CustomPayloadParam from "@/views/manage/payment/components/customPayloadParam/param";
import PaymentProcessType from "@/views/manage/payment/components/paymentProcessType";
import _ from "lodash";
import DeleteItem from "@/components/DeleteItem";
import { checkUniqueName } from "@/api/paymentgateways";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    CustomPayloadParam,
    PaymentProcessType,
    DeleteItem,
    SaveButton
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("paymentprofiles", {
      paymentProfileParams: state => state.paymentProfileParams,
      loading: state => state.loading
    }),

    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    }
  },

  data() {
    let validatePaymentGatewayName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === 0 && value !== this.contentForm.payment_gateway_name) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Payment gateway already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Payment gateway already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    return {
      rules: {
        payment_gateway_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validatePaymentGatewayName, trigger: "blur" }
        ]
      },
      isSubmitting: false,
      isChecking: false,
      paymentGatewayJson: {
        api_url: {
          test: "",
          live: ""
        },
        authentication: {},
        payment_process_types: {},
        request_parameters: {},
        response_parameters: {}
      },
      displayEditor: false,
      requestParameters: [],

      authenticationParams: [],

      authRequestParams: [],
      authResponseParams: [],
      requestParams: [],
      responseParams: [],
      paymentProcessTypes: [],
      activeTab: "basic",
      componentKey: 0,

      availableRequestTypes: ["json", "form_data", "text/xml"],
      availableResponseTypes: [
        "json",
        "array",
        "parse_str",
        "trust_commerce_explode",
        "xml"
      ],

      forceDeleteEventName: "initiate-force-delete-PaymentGateway"
    };
  },

  methods: {
    ...mapActions("paymentgateways", {
      createPaymentGateway: "createPaymentGateway",
      updatePaymentGateway: "updatePaymentGateway",
      deleteContentMethod: "deletePaymentGateway",
      undoDeleteContent: "undoDeletePaymentGateway"
    }),

    handleJSONClick() {},

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createPaymentGateway
              : this.updatePaymentGateway;

          this.$set(this.contentForm.json_data, "payment_process_types", {});
          for (const i in this.paymentProcessTypes) {
            if (
              this.$refs["pptRef" + i] &&
              this.$refs["pptRef" + i].length > 0
            ) {
              if (
                this.$refs["pptRef" + i][0].paymentProcessTypeData.is_editing
              ) {
                this.$refs["pptRef" + i][0].submit();
              }
            }

            this.$set(
              this.contentForm.json_data.payment_process_types,
              this.paymentProcessTypes[i].key,
              this.paymentProcessTypes[i]
            );
          }

          this.$set(this.contentForm.json_data, "request_parameters", {});
          for (const i in this.requestParams) {
            if (
              this.$refs["reqRef" + i] &&
              this.$refs["reqRef" + i].length > 0
            ) {
              if (this.$refs["reqRef" + i][0].param.is_editing) {
                this.$refs["reqRef" + i][0].submit();
              }
            }

            this.$set(
              this.contentForm.json_data.request_parameters,
              this.requestParams[i].key,
              this.requestParams[i]
            );
          }

          this.$set(this.contentForm.json_data, "response_parameters", {});
          for (const i in this.responseParams) {
            if (
              this.$refs["respRef" + i] &&
              this.$refs["respRef" + i].length > 0
            ) {
              if (this.$refs["respRef" + i][0].param.is_editing) {
                this.$refs["respRef" + i][0].submit();
              }
            }

            this.$set(
              this.contentForm.json_data.response_parameters,
              this.responseParams[i].key,
              this.responseParams[i]
            );
          }

          if (
            this.contentForm.json_data.authentication &&
            this.contentForm.json_data.authentication.type &&
            this.contentForm.json_data.authentication.type !== "none"
          ) {
            const authType = this.contentForm.json_data.authentication.type;
            this.$set(
              this.contentForm.json_data.authentication[authType],
              "params",
              this.authRequestParams
            );
            this.$set(
              this.contentForm.json_data.authentication[authType],
              "response_params",
              this.authResponseParams
            );
          }

          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Payment gateway added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Payment gateway updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    // new
    selectAuthenticationType(authType) {
      this.authRequestParams.splice(0, this.authRequestParams.length);
      this.$set(this.contentForm.json_data, "authentication", {});
      this.$set(this.contentForm.json_data.authentication, "type", authType);

      if (authType !== "none") {
        this.$set(this.contentForm.json_data.authentication, authType, {});

        this.$set(
          this.contentForm.json_data.authentication[authType],
          "params",
          []
        );
        this.$set(
          this.contentForm.json_data.authentication[authType],
          "response_params",
          []
        );

        if (authType === "bearer_access_token") {
          this.$set(
            this.contentForm.json_data.authentication.bearer_access_token,
            "http_method",
            "post"
          );
          this.$set(
            this.contentForm.json_data.authentication.bearer_access_token,
            "resource_path",
            ""
          );
        } else if (authType === "backend_function") {
          this.$set(
            this.contentForm.json_data.authentication.backend_function,
            "function",
            ""
          );
        }
      }
    },

    addPaymentProcessType() {
      this.paymentProcessTypes.push({
        name: "",
        key: "",
        label: "",
        resource_path: "",
        http_method: "post",
        request_params: {
          required: [],
          optional: []
        },
        url_params: [],
        response_params: [],
        is_editing: true
      });
    },

    updatePaymentProcessType(index, paymentProcessType) {
      this.$set(this.paymentProcessTypes, index, paymentProcessType);
    },

    removePaymentProcessType(index) {
      this.paymentProcessTypes.splice(index, 1);
    },

    addRequestParameter() {
      this.requestParams.push({
        key: "",
        label: "",
        required: false,
        type: "payment_node:variable",
        default_data: "",
        test_data: "",
        data: "",
        path: "",
        is_editing: true
      });
    },

    removeRequestParameter(index) {
      this.requestParams.splice(index, 1);
    },

    updateRequestParameter(index, parameter) {
      this.$set(this.requestParams, index, parameter);
    },

    addResponseParameter() {
      this.responseParams.push({
        key: "",
        label: "",
        required: false,
        type: "response",
        default_data: "",
        path: "",
        is_editing: true
      });
    },

    updateResponseParameter(index, parameter) {
      this.$set(this.responseParams, index, parameter);
    },

    removeResponseParameter(index) {
      this.responseParams.splice(index, 1);
    },

    resetForm() {
      this.paymentProcessTypes.splice(0, this.paymentProcessTypes.length);
      this.requestParams.splice(0, this.requestParams.length);
      this.responseParams.splice(0, this.responseParams.length);
      this.authRequestParams.splice(0, this.authRequestParams.length);
      this.authResponseParams.splice(0, this.authResponseParams.length);
    },

    initializePaymentGateway() {
      this.isSubmitting = true;

      if (this.id !== -1) {
        this.paymentProcessTypes = [
          ...Object.values(this.contentForm.json_data.payment_process_types)
        ];
        this.requestParams = [
          ...Object.values(this.contentForm.json_data.request_parameters)
        ];
        this.responseParams = [
          ...Object.values(this.contentForm.json_data.response_parameters)
        ];

        let authType = _.get(
          this.contentForm,
          ["json_data", "authentication", "type"],
          ""
        );
        if (authType && authType !== "none") {
          let authRequestParams = _.get(
            this.contentForm,
            ["json_data", "authentication", authType, "params"],
            []
          );
          let authResponseParams = _.get(
            this.contentForm,
            ["json_data", "authentication", authType, "response_params"],
            []
          );
          this.authRequestParams = [...authRequestParams];
          this.authResponseParams = [...authResponseParams];
        }
      } else {
        this.$set(this.contentForm, "json_data", {});
        this.$set(this.contentForm.json_data, "api_url", {});
        this.$set(this.contentForm.json_data.api_url, "test", "");
        this.$set(this.contentForm.json_data.api_url, "live", "");
        this.$set(this.contentForm.json_data, "authentication", {});
        this.$set(this.contentForm.json_data, "request_parameters", {});
        this.$set(this.contentForm.json_data, "response_parameters", {});
        this.$set(this.contentForm.json_data, "payment_process_types", {});
        this.$set(this.contentForm.json_data, "request_type", "json");
        this.$set(this.contentForm.json_data, "response_type", "json");
        this.resetForm();
      }

      this.isSubmitting = false;
    },

    closeModal() {
      console.log("close modal");
      this.isSubmitting = false;
      this.resetForm();
      this.handleCancel();
    }
  },

  watch: {
    openModal(val) {
      if (val === true) {
        this.activeTab = "basic";
        this.initializePaymentGateway();
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";
@import "~@/styles/button.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
